import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
//import QuotesModal from 'components/broker/QuotesModal'
import BrokerAPI from 'config/api/BrokerAPI'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { pensionTypes, pensionSizes } from 'config/constants/Constants'

import DetailsContext from 'config/context/DetailsContext'

function PENQuoteDetails(props) {
    const api = props.admin ? new AdminAPI() : new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId || props.companyId

    const quote = props.lead.quote
    
    const [currentValue, setCurrentValue] = useState(quote.currentValue || '')
    const [currentProvider, setCurrentProvider] = useState(quote.currentProvider || '')
    const [previousEmployers, setPreviousEmployers] = useState(quote.previousEmployers || '')
    const [primaryGoal, setPrimaryGoal] = useState(quote.primaryGoal || '')
    const [statements, setStatements] = useState(quote.statements)
    const [type, setType] = useState(quote.type || '')
    const [showContent, setShowContent] = useState(props.showQuoteContent)
    const [showQuotesModal, setShowQuotesModal] = useState(false)
 
    async function updateQuote() {
        try {
            let quote = {
                currentValue,
                currentProvider,
                previousEmployers,
                primaryGoal,
                statements,
                type
            }
            
            let result = null
            if (props.admin) {
                result = await api.updateLeadQuote(props.lead.date, props.lead.leadId, quote)
            }
            else {
                result = await api.updateLeadQuote(companyId, props.lead.product, props.lead.phone, quote)
            }

            props.updateLead(result)
            NotificationManager.success('Details updated')
            setShowContent(false)
        } catch(e) {
            onError(e)
        }
    }

    // function updatePremium(premium) {
    //     let formatted = premium.replace(/€/g, '')
    //     setPremium(formatted)
    // }

    // function updateCurrentPremium(currentPremium) {
    //     let formatted = currentPremium.replace(/€/g, '')
    //     setCurrentPremium(formatted)
    // }
    
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Pension Details
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => setShowContent(!showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {showContent &&
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Type</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        IconComponent={KeyboardArrowDown}
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                        //disabled
                                    >
                                        {pensionTypes.map((type) => {
                                            return <MenuItem value={type}>{type}</MenuItem>       
                                        })}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>{props.stage === 'won' ? 'Income Generated' : 'Current Value'}</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    {props.lead.stage === 'won' ? (
                                        <OutlinedInput
                                            fullWidth
                                            type='text'
                                            value={currentValue}
                                            onChange={(e) => setCurrentValue(e.target.value)}
                                            disabled={props.permissions === 'broker'}
                                        />
                                    ) : (
                                        <Select
                                            multiple={false}
                                            IconComponent={KeyboardArrowDown}
                                            value={currentValue}
                                            onChange={(e) => setCurrentValue(e.target.value)}
                                            //disabled
                                        >
                                            {pensionSizes.map((size) => {
                                                return <MenuItem value={size}>{size}</MenuItem>       
                                            })}
                                        </Select>
                                    )}
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        {/*
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Current Provider</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        defaultValue={''}
                                        IconComponent={KeyboardArrowDown}
                                        value={currentProvider}
                                        onChange={(e) => setCurrentProvider(e.target.value)}
                                    >
                                        {providerOptions.map((option, i) => {
                                            return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        */}
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Current Provider</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={currentProvider}
                                        onChange={(e) => setCurrentProvider(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Previous Employers</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={previousEmployers}
                                        onChange={(e) => setPreviousEmployers(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Primary Goal</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={primaryGoal}
                                        onChange={(e) => setPrimaryGoal(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Statements on File</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        IconComponent={KeyboardArrowDown}
                                        value={statements}
                                        onChange={(e) => setStatements(e.target.value)}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        
                        {/*
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Type</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        IconComponent={KeyboardArrowDown}
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                    >
                                        <MenuItem value='Pension Transfer'>Pension Transfer</MenuItem>
                                        <MenuItem value='Pension Review'>Pension Review</MenuItem>
                                        <MenuItem value='Start a Pension'>Start a Pension</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        */}
                        
                        <Grid item xs={12} lg={12}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {/*
                                    <Button
                                        onClick={() => setShowQuotesModal(true)}
                                        color='primary'
                                        disabled
                                    >
                                        Generate Quotes
                                    </Button>
                                    */}
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        size='medium'
                                        disabled={false}
                                        onClick={() => updateQuote()}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*showQuotesModal &&
                        <QuotesModal 
                            isOpen={showQuotesModal}
                            close={() => setShowQuotesModal(false)}
                            clientDetails={props.clientDetails}
                            application={application}
                            updateApplication={(provider, premium, noPeople, coverAmount, coverAmount2, illnessCover, illnessCoverAmount, illnessCoverAmount2, policyTerm, convertibleOption, indexation) => updateApplication(provider, premium, noPeople, coverAmount, coverAmount2, illnessCover, illnessCoverAmount, illnessCoverAmount2, policyTerm, convertibleOption, indexation)}
                            type={props.type}
                        />
                    */}
                </CardContent>
            }
        </Card>
    )
}

export default PENQuoteDetails