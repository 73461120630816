import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import CompaniesTable from 'components/admin/Energy/CompaniesTable'
import DeliveriesTable from 'components/admin/Energy/DeliveriesTable'
import LeadsTable from 'components/admin/Energy/LeadsTable'
import { getMoments } from 'config/helpers/Helpers'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'
import PeriodSelect from 'components/general/PeriodSelect'
import SolarTotals from 'components/admin/Energy/SolarTotals'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function SolarFinder() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new EnergyAPI()

    const [showStatus, setShowStatus] = useState('active')
    const [companies, setCompanies] = useState([])
    const [showCompanies, setShowCompanies] = useState([])
    const [deliveries, setDeliveries] = useState([])
    const [showDeliveries, setShowDeliveries] = useState([])
    const [showLeadsStatus, setShowLeadsStatus] = useState('Unallocated')
    const [leads, setLeads] = useState([])
    const [showLeads, setShowLeads] = useState([])
    const [eeLeads, setEeLeads] = useState([])
    const [period, setPeriod] = useState('thisWeek')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getCompanies()
    }, [])

    async function getCompanies() {
        setLoading(true)
        try {
            const companies = await api.getCompanies()
            console.log('companies: ', companies)
            companies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            const activeCompanies = companies.filter((company) => (company.active))

            const deliveries = await api.getSfDeliveries()
            console.log('deliveries: ', deliveries)
            const activeDeliveries = deliveries.filter((delivery) => (delivery.active && delivery.companyId !== 'pvg'))
            activeDeliveries.sort((a, b) => {
                return b.weight - a.weight
            })

            let dates = getMoments(period)
            console.log('from: ', dates.fromDate.format('DD/MM HH:mm'))
            console.log('to: ', dates.toDate.format('DD/MM HH:mm'))

            const leads = await api.getSfLeadsPeriod('Solar', dates.fromDate.valueOf(), dates.toDate.valueOf())
            leads.sort((a, b) => {
                return b.createdAt - a.createdAt
            })
            console.log('leads: ', leads.length)
            const eeLeads = await api.getEeLeadsPeriod('Solar', dates.fromDate.valueOf(), dates.toDate.valueOf())
            console.log('ee leads: ', eeLeads.length)

            // let combinedLeads = eeLeads.concat(leads)
            // console.log('combined leads: ', combinedLeads.length)

            // add count to deliveries
            for (let lead of leads) {
                if (lead.companyId || lead.isValid) {
                    let index = deliveries.findIndex(delivery => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                    if (index > -1) {
                        if (deliveries[index]['leadCount']) {
                            deliveries[index]['leadCount'] += 1
                        }
                        else {
                            deliveries[index]['leadCount'] = 1
                        }

                        if (deliveries[index]['revenue']) {
                            deliveries[index]['revenue'] += deliveries[index]['leadPrice']
                        }
                        else {
                            deliveries[index]['revenue'] = deliveries[index]['leadPrice']
                        }
                    }
                }
            }
            console.log('deliveries with lead count: ', deliveries)

            // get EE count
            for (let lead of eeLeads) {
                if (lead.companyId || lead.isValid) {
                    let index = deliveries.findIndex(delivery => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                    if (index > -1) {
                        if (deliveries[index]['eeLeadCount']) {
                            deliveries[index]['eeLeadCount'] += 1
                        }
                        else {
                            deliveries[index]['eeLeadCount'] = 1
                        }

                        if (deliveries[index]['revenue']) {
                            deliveries[index]['revenue'] += deliveries[index]['leadPrice']
                        }
                        else {
                            deliveries[index]['revenue'] = deliveries[index]['leadPrice']
                        }
                    }
                }
            }

            setCompanies(companies)
            setShowCompanies(activeCompanies)
            setDeliveries(deliveries)
            setShowDeliveries(activeDeliveries)
            setLeads(leads)
            setShowLeads(leads.filter((lead) => lead.companyId === null && lead.isValid))
            setEeLeads(eeLeads)
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updatePeriod(period, fromDate=null, toDate=null) {
        setPeriod(period)
        setLoading(true)
        let dates = period === 'custom' ? {
            fromDate: moment(fromDate).startOf('day'),
            toDate: moment(toDate).endOf('day')
        } : getMoments(period)

        try {
            const leads = await api.getSfLeadsPeriod('Solar', dates.fromDate.valueOf(), dates.toDate.valueOf())
            leads.sort((a, b) => {
                return b.createdAt - a.createdAt
            })
            console.log('leads: ', leads.length)
            const eeLeads = await api.getEeLeadsPeriod('Solar', dates.fromDate.valueOf(), dates.toDate.valueOf())
            console.log('ee leads: ', eeLeads.length)

            let newDeliveries = [...deliveries]
            newDeliveries.map((delivery) => {
                delivery['leadCount'] = 0
                delivery['eeLeadCount'] = 0
                delivery['revenue'] = 0
                return delivery
            })
            console.log('new deliveries: ', newDeliveries)

            for (let lead of leads) {
                if (lead.companyId || lead.isValid) {
                    let index = newDeliveries.findIndex(delivery => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                    if (index > -1) {
                        if (newDeliveries[index]['leadCount']) {
                            newDeliveries[index]['leadCount'] += 1
                        }
                        else {
                            newDeliveries[index]['leadCount'] = 1
                        }

                        if (newDeliveries[index]['revenue']) {
                            newDeliveries[index]['revenue'] += newDeliveries[index]['leadPrice']
                        }
                        else {
                            newDeliveries[index]['revenue'] = newDeliveries[index]['leadPrice']
                        }
                    }
                }
            }
            console.log('newDeliveries with lead count: ', newDeliveries)

            // get EE count
            for (let lead of eeLeads) {
                if (lead.companyId || lead.isValid) {
                    let index = deliveries.findIndex(delivery => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                    if (index > -1) {
                        if (newDeliveries[index]['eeLeadCount']) {
                            newDeliveries[index]['eeLeadCount'] += 1
                        }
                        else {
                            newDeliveries[index]['eeLeadCount'] = 1
                        }

                        if (newDeliveries[index]['revenue']) {
                            newDeliveries[index]['revenue'] += newDeliveries[index]['leadPrice']
                        }
                        else {
                            newDeliveries[index]['revenue'] = newDeliveries[index]['leadPrice']
                        }
                    }
                }
            }

            let checkStatus = showStatus === 'active' ? true : false
            let showDeliveries = newDeliveries.filter((delivery) => (delivery.active === checkStatus))

            let showLeads = []
            if (showLeadsStatus === 'Unallocated') {
                showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
            }
            else if (showLeadsStatus === 'Allocated') {
                showLeads = leads.filter((lead) => lead.companyId !== null)
            }
            else if (showLeadsStatus === 'Rejected') {
                showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
            }

            setDeliveries(newDeliveries)
            setShowDeliveries(showDeliveries)
            setLeads(leads)
            setShowLeads(showLeads)
            setFromDate(dates.fromDate)
            setToDate(dates.toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateCompany(company) {
        const index = companies.findIndex(oldCompany => (oldCompany.companyId === company.companyId))
        console.log('company index: ', index)

        let newCompanies = [...companies]
        newCompanies[index] = company
        let checkStatus = showStatus === 'active' ? true : false
        let showCompanies = newCompanies.filter((company) => (company.active === checkStatus))
        console.log(showCompanies)
        setCompanies(newCompanies)
        setShowCompanies(showCompanies)
    }

    function updateDelivery(delivery) {
        const index = deliveries.findIndex(oldDelivery => ((oldDelivery.companyId === delivery.companyId) && (oldDelivery.product === delivery.product)))
        console.log('delivery index: ', index)

        let newDeliveries = [...deliveries]
        newDeliveries[index] = delivery
        console.log(newDeliveries)
        let checkStatus = showStatus === 'active' ? true : false
        let showDeliveries = newDeliveries.filter((delivery) => (delivery.active === checkStatus))
        console.log(showDeliveries)
        setDeliveries(newDeliveries)
        setShowDeliveries(showDeliveries)
    }

    // function updateDelivery(delivery) {
    //     // Create a new array with the updated object
    //     const updatedDeliveries = deliveries.map(oldDelivery => 
    //         (oldDelivery.companyId === delivery.companyId) && (oldDelivery.product === delivery.product) ? { ...oldDelivery, weeklyCap: delivery.weeklyCap } : oldDelivery
    //     )
        
    //     let checkStatus = showStatus === 'active' ? true : false
    //     // Update state with the new array
    //     setDeliveries(updatedDeliveries)
    //     setShowDeliveries(updatedDeliveries.filter((delivery) => (delivery.active === checkStatus)))
    // }

    function updateLead(lead) {
        const index = leads.findIndex(oldLead => (oldLead.leadId === lead.leadId))
        console.log('lead index: ', index)

        let newLeads = [...leads]
        newLeads[index] = lead
        console.log(newLeads)
        setLeads(newLeads)
        if (showLeadsStatus === 'Unallocated') {
            setShowLeads(newLeads.filter((lead) => lead.companyId === null && lead.isValid))
        }
        if (showLeadsStatus === 'Rejected') {
            setShowLeads(newLeads.filter((lead) => lead.companyId === null && !lead.isValid))
        }
    }

    function updateShowStatus(showStatus) {
        console.log('show status: ', showStatus)
        let checkStatus = showStatus === 'active' ? true : false
        let showCompanies = companies.filter((company) => (company.active === checkStatus))
        let showDeliveries = deliveries.filter((delivery) => (delivery.active === checkStatus))
        setShowCompanies(showCompanies)
        setShowDeliveries(showDeliveries)
        setShowStatus(showStatus)
    }

    function updateShowLeadsStatus(showLeadsStatus) {
        //let checkStatus = showStatus === 'paid' ? true : false
        let showLeads = []
        if (showLeadsStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showLeadsStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showLeadsStatus === 'Rejected') {
            showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        setShowLeads(showLeads)
        setShowLeadsStatus(showLeadsStatus)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <PeriodSelect
                                        period={period}
                                        updatePeriod={(period, fromDate, toDate) => updatePeriod(period, fromDate, toDate)}
                                        fromDate={fromDate}
                                        toDate={toDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={showStatus}
                                                onChange={(e) => updateShowStatus(e.target.value)}
                                            >
                                                <MenuItem value='active'>Active</MenuItem>
                                                <MenuItem value='inactive'>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <Box
                                        fontSize='12px'
                                        color={theme.palette.gray[600]}
                                        //marginBottom='0.2rem'
                                        //marginLeft='1rem'
                                        marginTop='0.5rem'
                                        textAlign='left'
                                        //className='online-discount'
                                    >
                                        <Box component='small' fontSize='100%'>
                                            Status
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SolarTotals
                                leads={leads}
                                deliveries={deliveries} // active + inactive
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DeliveriesTable 
                                deliveries={showDeliveries}
                                companies={companies}
                                refreshDeliveries={() => getCompanies()}
                                updateDelivery={(delivery) => updateDelivery(delivery)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                title={showStatus === 'active' ? 'SF Deliveries' : 'Inactive SF Deliveries'}
                                site='sf'
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default SolarFinder