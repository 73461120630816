export const productOptions = [
    {value: 'income-protection', label: 'Income Protection'},
    {value: 'mortgage-protection', label: 'Mortgage Protection'},
    {value: 'life-insurance', label: 'Life Insurance'},
    {value: 'pensions', label: 'Pensions'},
    {value: 'health-insurance', label: 'Health Insurance'},
    {value: 'home-insurance', label: 'Home Insurance'},
    {value: 'business-insurance', label: 'Business Insurance'},
    {value: 'gadget-insurance', label: 'Gadget Insurance'},
    {value: 'pet-insurance', label: 'Pet Insurance'},
    {value: 'travel-insurance', label: 'Travel Insurance'}
]

export const eeProductOptions = [
    {value: 'Solar', label: 'Solar'},
    {value: 'EV Charger', label: 'EV Charger'},
    {value: 'Insulation', label: 'Insulation'},
    {value: 'Boiler', label: 'Boiler'},
    {value: 'Heat Pump', label: 'Heat Pump'}
]

export const defaultProducts = {
    'income-protection': true,
    'mortgage-protection': true,
    'life-insurance': true,
    'pensions': true,
    'health-insurance': false,
    'home-insurance': false,
    'business-insurance': false,
    'gadget-insurance': false,
    'pet-insurance': false,
    'travel-insurance': false,
    'general': false
}

export const defaultPensionPricing = {
    'General Enquiry (no value)': 20,
    '€0 - €10,000': 10,
    '€10,000 - €50,000': 50,
    '€50,000 - €150,000': 125,
    '€150,000 - €500,000': 500,
    '€500,000+': 1000
}

export const ciPrices = {
    'income-protection': 30,
    'mortgage-protection': 0,
    'life-insurance': 0,
    'health-insurance': 5,
    'home-insurance': 4,
    'business-insurance': 4,
    'gadget-insurance': 0,
    'pet-insurance': 0,
    'travel-insurance': 0,
    'general': 0
}

export const pensionSizes = [
    '€0 - €10,000',
    '€10,000 - €50,000',
    '€50,000 - €150,000',
    '€150,000 - €500,000',
    '€500,000+'
]

export const pensionTypes = [
    'Callback',
    'General Pension',
    'Pension Property',
    'Start Company Pension',
    'Start Pension',
    'Pension Review',
    'Pension Transfer',
    'Redundancy'
]

export const leadPipelineStages = {
    new: 'New',
    attempting: 'Attempting', // attempting contact
    engaged: 'Engaged',
    meeting: 'Meeting', // meeting set
    pending: 'Pending', // pending outcome
    won: 'Won',
    lost: 'Lost'
}

export const pipelineStageColors = {
    new: 'grey',
    attempting: 'orange',
    engaged: 'yellow',
    meeting: 'blue',
    pending: 'purple',
    won: 'green',
    lost: 'red'
}

export const genderOptions = [
    {value: 'Male', label: 'Male'},
    {value: 'Female', label: 'Female'}
]

export const maritalStatusOptions = [
    {value: 'married', label: 'Married'},
    {value: 'single', label: 'Single'},
    {value: 'divorced', label: 'Divorced'},
    {value: 'civil-partner', label: 'Civil Partnership'},
    {value: 'separated', label: 'Separated'},
    {value: 'widowed', label: 'Widowed'},
]

export const smokingStatusOptions = [
    {value: 'Smoker', label: 'Smoker'},
    {value: 'Non Smoker', label: 'Non Smoker'}
]

export const employmentStatusOptions = [
    {value: 'Employee', label: 'Employee'},
    {value: 'Company Director', label: 'Company Director'},
    {value: 'Self Employed', label: 'Self Employed'},
    {value: 'Unemployed', label: 'Unemployed'}
]

export const reminderOptions = {
    atTime: 'At time',
    minutes5: '5 minutes before',
    minutes15: '15 minutes before',
    minutes30: '30 minutes before',
    hour1: '1 hour before',
    day1: '1 day before'
}

export const durationOptions = {
    minutes15: '15 minutes',
    minutes30: '30 minutes',
    hour1: '1 hour'
}

export const callOutcomes = {
    answer: 'Answer',
    noAnswer: 'No Answer'
}

export const providerOptions = [
    {value: 'Aviva', label: 'Aviva'},
    {value: 'Irish Life', label: 'New Ireland'},
    {value: 'Royal London', label: 'Royal London'},
    {value: 'New Ireland', label: 'New Ireland'},
    {value: 'Zurich Life', label: 'Zurich Life'}
]

export const riskLevelOptions = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: 'D', label: 'D'}
]

export const countyOptions = [
    {value: 'All', label: 'All'},
    //{value: 'Antrim', label: 'Antrim'},
    //{value: 'Armagh', label: 'Armagh'},
    {value: 'Carlow', label: 'Carlow'},
    {value: 'Cavan', label: 'Cavan'},
    {value: 'Clare', label: 'Clare'},
    {value: 'Cork', label: 'Cork'},
    //{value: 'Derry', label: 'Derry'},
    {value: 'Donegal', label: 'Donegal'},
    //{value: 'Down', label: 'Down'},
    {value: 'Dublin', label: 'Dublin'},
    //{value: 'Fermanagh', label: 'Fermanagh'},
    {value: 'Galway', label: 'Galway'},
    {value: 'Kerry', label: 'Kerry'},
    {value: 'Kildare', label: 'Kildare'},
    {value: 'Kilkenny', label: 'Kilkenny'},
    {value: 'Laois', label: 'Laois'},
    {value: 'Leitrim', label: 'Leitrim'},
    {value: 'Limerick', label: 'Limerick'},
    {value: 'Longford', label: 'Longford'},
    {value: 'Louth', label: 'Louth'},
    {value: 'Mayo', label: 'Mayo'},
    {value: 'Meath', label: 'Meath'},
    {value: 'Monaghan', label: 'Monaghan'},
    {value: 'Offaly', label: 'Offaly'},
    {value: 'Roscommon', label: 'Roscommon'},
    {value: 'Sligo', label: 'Sligo'},
    {value: 'Tipperary', label: 'Tipperary'},
    //{value: 'Tyrone', label: 'Tyrone'},
    {value: 'Waterford', label: 'Waterford'},
    {value: 'Westmeath', label: 'Westmeath'},
    {value: 'Wexford', label: 'Wexford'},
    {value: 'Wicklow', label: 'Wicklow'}
]

export const countryOptions = [
    {value: 'Ireland', label: 'Ireland'}
]

export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$') // standard email format
export const validPhone = new RegExp('^[0-9+]{9,15}$') // string between 9 and 15 characters long with numbers or + symbol
export const validDob = new RegExp('^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2}$') // Date (MM/DD/YYYY)/(MM-DD-YYYY)/(MM.DD.YYYY)/(MM DD YYYY)

export const timeOptions = [
    '00:00',
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
    '03:15',
    '03:30',
    '03:45',
    '04:00',
    '04:15',
    '04:30',
    '04:45',
    '05:00',
    '05:15',
    '05:30',
    '05:45',
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
    '21:15',
    '21:30',
    '21:45',
    '22:00',
    '22:15',
    '22:30',
    '22:45',
    '23:00',
    '23:15',
    '23:30',
    '23:45'
]